// Fonts
/////////////////////////////

$default-font-stack: 'brooklyn', sans-serif !default;

// Body Font: Brooklyn
//------------------------------------------//

@font-face {
  font-family: 'brooklyn';
  src: url('../assets/fonts/brooklyn/BrooklynSamuelsFive-Medium.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/brooklyn/BrooklynSamuelsFive-Medium.otf')  format('opentype'),
	     url('../assets/fonts/brooklyn/BrooklynSamuelsFive-Medium.woff') format('woff'),
       url('../assets/fonts/brooklyn/BrooklynSamuelsFive-Medium.ttf')  format('truetype'), url('../assets/fonts/brooklyn/BrooklynSamuelsFive-Medium.svg#BrooklynSamuelsFive-Medium') format('svg');
  font-weight: 100;
  font-style: normal;
}

@mixin body-medium {
  font-family: 'brooklyn';
  font-weight: 100;
  font-style: normal;
}

.body-regular {
  @include body-medium;
}


@font-face {
  font-family: 'brooklyn';
  src: url('../assets/fonts/brooklyn/BrooklynSamuelsFive-Bold.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/brooklyn/BrooklynSamuelsFive-Bold.otf')  format('opentype'),
	     url('../assets/fonts/brooklyn/BrooklynSamuelsFive-Bold.woff') format('woff'), url('../assets/fonts/brooklyn/BrooklynSamuelsFive-Bold.ttf')  format('truetype'), url('../assets/fonts/brooklyn/BrooklynSamuelsFive-Bold.svg#BrooklynSamuelsFive-Bold') format('svg');
  font-weight: 900;
  font-style: normal;
}

@mixin body-bold {
  font-family: 'brooklyn';
  font-weight: 900;
  font-style: normal;
}

.body-bold {
  @include body-bold;
}


// Headline Serif Font: Dosis
//------------------------------------------//

@font-face {
  font-family: 'dosis';
  src: url('../assets/fonts/dosis/dosis-light-webfont.eot');
  src: url('../assets/fonts/dosis/dosis-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/dosis/dosis-light-webfont.woff2') format('woff2'),
       url('../assets/fonts/dosis/dosis-light-webfont.woff') format('woff'),
       url('../assets/fonts/dosis/dosis-light-webfont.ttf') format('truetype'),
       url('../assets/fonts/dosis/dosis-light-webfont.svg#dosislight') format('svg');
  font-weight: 100;
  font-style: normal;
}

@mixin dosis-light {
  font-family: 'dosis';
  font-weight: 100;
  font-style: normal;
}

.dosis-light {
  @include dosis-light;
}

@font-face {
  font-family: 'dosis';
  src: url('../assets/fonts/dosis/dosis-regular-webfont.eot');
  src: url('../assets/fonts/dosis/dosis-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/dosis/dosis-regular-webfont.woff2') format('woff2'),
       url('../assets/fonts/dosis/dosis-regular-webfont.woff') format('woff'),
       url('../assets/fonts/dosis/dosis-regular-webfont.ttf') format('truetype'),
       url('../assets/fonts/dosis/dosis-regular-webfont.svg#dosisregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@mixin dosis-regular {
  font-family: 'dosis';
  font-weight: 400;
  font-style: normal;
}

.dosis-regular {
  @include dosis-regular;
}


@font-face {
  font-family: 'dosis';
  src: url('../assets/fonts/dosis/dosis-medium-webfont.eot');
  src: url('../assets/fonts/dosis/dosis-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/dosis/dosis-medium-webfont.woff2') format('woff2'),
       url('../assets/fonts/dosis/dosis-medium-webfont.woff') format('woff'),
       url('../assets/fonts/dosis/dosis-medium-webfont.ttf') format('truetype'),
       url('../assets/fonts/dosis/dosis-medium-webfont.svg#dosismedium') format('svg');
  font-weight: 700;
  font-style: normal;
}

@mixin dosis-medium {
  font-family: 'dosis';
  font-weight: 700;
  font-style: normal;
}

.dosis-medium {
  @include dosis-medium;
}


@font-face {
  font-family: 'dosis';
  src: url('../assets/fonts/dosis/dosis-bold-webfont.eot');
  src: url('../assets/fonts/dosis/dosis-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/dosis/dosis-bold-webfont.woff2') format('woff2'),
       url('../assets/fonts/dosis/dosis-bold-webfont.woff') format('woff'),
       url('../assets/fonts/dosis/dosis-bold-webfont.ttf') format('truetype'),
       url('../assets/fonts/dosis/dosis-bold-webfont.svg#dosisbold') format('svg');
  font-weight: 900;
  font-style: normal;
}

@mixin dosis-bold {
  font-family: 'dosis';
  font-weight: 900;
  font-style: normal;
}

.dosis-bold {
  @include dosis-bold;
}
