// Tabbable tabs
// -------------------------
// Hide tabbable panes to start, show them when `.active`
.tab-content {
  > .tab-pane {
    display: none;
  }

  > .active {
    display: block;
  }
}
//
// Component animations
// --------------------------------------------------
// Transitions
@mixin transition($transition...) {
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin transition-property($transition-property...) {
  -webkit-transition-property: $transition-property;
  transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
  transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
  -webkit-transition-duration: $transition-duration;
  transition-duration: $transition-duration;
}
@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
  -moz-transition: -moz-transform $transition;
  -o-transition: -o-transform $transition;
  transition: transform $transition;
}
// Heads up!
//
// We don't use the `.opacity()` mixin here since it causes a bug with text
// fields in IE7-8. Source: https://github.com/twitter/bootstrap/pull/3552.

.fade {
  opacity: 0;
  @include transition(opacity 0.15s linear);

  &.in {
    opacity: 1;
  }
}

.collapse {
  display: none;

  &.in {
    display: block;
  }
  // [converter] extracted tr&.in to tr.collapse.in
  // [converter] extracted tbody&.in to tbody.collapse.in
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition(height 0.35s ease);
}
