// CLEARFIX
////////////////////////
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// ANIMATE
////////////////////////
@mixin animate($property, $speed, $effect) {
  -webkit-transition: $property $speed $effect;
  -moz-transition: $property $speed $effect;
  -o-transition: $property $speed $effect;
  transition: $property $speed $effect;
}

// BREAK
////////////////////////
@mixin break($margin-bottom) {
  display: block;
  clear: both;
  margin-bottom: $margin-bottom;
}

// NOSELECT
////////////////////////
@mixin noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// RESPONSIVE IMAGE
////////////////////////
@mixin img-responsive {
  display: block;
  width: 100%;
  height: auto;
}

// ROUNDED CORNERS
////////////////////////
@mixin rounded-corners($size) {
  -moz-border-radius: $size;
  -webkit-border-radius: $size;
  border-radius: $size;
  -khtml-border-radius: $size;
}

// GRADIENTS
////////////////////////
@mixin gradient($top-color, $bot-color, $fallback) {
  /* fallback */
  background-color: $fallback;
  /* Safari 4-5, Chrome 1-9 */
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from($top-color), to($bot-color));
  /* Safari 5.1, Chrome 10+ */
  background: -webkit-linear-gradient(top, $top-color, $bot-color);
  /* Firefox 3.6+ */
  background: -moz-linear-gradient(top, $top-color, $bot-color);
  /* IE 10 */
  background: -ms-linear-gradient(top, $top-color, $bot-color);
  /* Opera 11.10+ */
  background: -o-linear-gradient(top, $top-color, $bot-color);
}

// Built in retina support
@mixin sprite {
  background: url("../images/sprite_ui.png") no-repeat;
  display: block;
  text-indent: -9999em;
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    background-image: url("../images/sprite_ui_2x.png") !important;
    -webkit-background-size: 800px 800px;
    background-size: 800px 800px;
  }
}

/* ================================================================================ */
/* BOOTSTRAP GRID SYSTEM */
/* ================================================================================ */
// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
  -moz-box-sizing: $boxmodel;
  box-sizing: $boxmodel;
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
  -moz-column-count: $column-count;
  column-count: $column-count;
  -webkit-column-gap: $column-gap;
  -moz-column-gap: $column-gap;
  column-gap: $column-gap;
}

// Responsive utilities
// -------------------------
// More easily include all the states for responsive-utilities.less.
// [converter] $parent hack
@mixin responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }

  table#{$parent} {
    display: table;
  }

  tr#{$parent} {
    display: table-row !important;
  }

  td#{$parent},
  th#{$parent} {
    display: table-cell !important;
  }
}
// [converter] $parent hack
@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}
// Centered container element
@mixin container-fixed() {
  margin-right: auto;
  margin-left: auto;
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  @include clearfix();
}
// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
  margin-left: ($gutter / -2);
  margin-right: ($gutter / -2);
  @include clearfix();
}
// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}
@mixin make-xs-column-offset($columns) {
  @media (min-width: $screen-xs-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xs-column-push($columns) {
  @media (min-width: $screen-xs-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xs-column-pull($columns) {
  @media (min-width: $screen-xs-min) {
    right: percentage(($columns / $grid-columns));
  }
}
// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  @media (min-width: $screen-sm-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-offset($columns) {
  @media (min-width: $screen-sm-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-push($columns) {
  @media (min-width: $screen-sm-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-pull($columns) {
  @media (min-width: $screen-sm-min) {
    right: percentage(($columns / $grid-columns));
  }
}
// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  @media (min-width: $screen-md-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-offset($columns) {
  @media (min-width: $screen-md-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-push($columns) {
  @media (min-width: $screen-md-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-pull($columns) {
  @media (min-width: $screen-md-min) {
    right: percentage(($columns / $grid-columns));
  }
}
// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  @media (min-width: $screen-lg-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-offset($columns) {
  @media (min-width: $screen-lg-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-push($columns) {
  @media (min-width: $screen-lg-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-pull($columns) {
  @media (min-width: $screen-lg-min) {
    right: percentage(($columns / $grid-columns));
  }
}
// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.
// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin make-grid-columns() {
  $list: '';
  $i: 1;
  $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }

  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }
}
// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns($class) {
  $list: '';
  $i: 1;
  $list: ".col-#{$class}-#{$i}";
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-#{$class}-#{$i}";
  }

  #{$list} {
    float: left;
  }
}
@mixin calc-grid-column($index, $class, $type) {
  @if ($type == width) and ($index > 0) {
    .col-#{$class}-#{$index} {
      width: percentage(($index / $grid-columns));
    }
  }
  @if ($type == push) {
    .col-#{$class}-push-#{$index} {
      left: percentage(($index / $grid-columns));
    }
  }
  @if ($type == pull) {
    .col-#{$class}-pull-#{$index} {
      right: percentage(($index / $grid-columns));
    }
  }
  @if ($type == offset) {
    .col-#{$class}-offset-#{$index} {
      margin-left: percentage(($index / $grid-columns));
    }
  }
}
// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $class, $type);
  }
}
// Create grid for specific class
@mixin make-grid($class) {
  @include float-grid-columns($class);
  @include loop-grid-columns($grid-columns, $class, width);
  @include loop-grid-columns($grid-columns, $class, pull);
  @include loop-grid-columns($grid-columns, $class, push);
  @include loop-grid-columns($grid-columns, $class, offset);
}
