blockquote,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
section,
ul {
  margin: 0;
  padding: 0;
}

main,
section {
  display: block;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
}

strong {
  font-weight: bold;
}

a,
button {
  color: inherit;
}

a {
  text-decoration: none;
}

button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

:focus {
  outline: 0;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}
