// ============================
// ANIMATIONS
// Custom element animations.
// ============================
//Dribble Bounce
@keyframes dribble {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-8px);
  }

  60% {
    transform: translateY(-5px);
  }
}
@keyframes dribbleRight {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateX(0) translateY(-50%);
  }

  40% {
    transform: translateX(8px) translateY(-50%);
  }

  60% {
    transform: translateX(5px) translateY(-50%);
  }
}
@keyframes dribbleLeft {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateX(0) translateY(-50%) rotate(-90deg);
  }

  40% {
    transform: translateX(-8px) translateY(-50%) rotate(-90deg);
  }

  60% {
    transform: translateX(-5px) translateY(-50%) rotate(-90deg);
  }
}
@keyframes dribbleLeftStraight {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateX(0) translateY(-50%);
  }

  40% {
    transform: translateX(8px) translateY(-50%);
  }

  60% {
    transform: translateX(5px) translateY(-50%);
  }
}
//Circle Orbitting
@keyframes orbit {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.orbit {
  animation-name: orbit;
}
/*
- Rotate Animation
-------------------- */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.rotate {
  animation-name: rotate;
}
