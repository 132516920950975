// greyscale
//-----------------------------
$black-10: rgba(0,0,0,.1);
$black-20: rgba(0,0,0,.2);
$black-30: rgba(0,0,0,.3);
$black-40: rgba(0,0,0,.4);
$black-50: rgba(0,0,0,.5);
$black-60: rgba(0,0,0,.6);
$black-70: rgba(0,0,0,.7);
$black-80: rgba(0,0,0,.8);
$black-90: rgba(0,0,0,.9);
$white-10: rgba(255,255,255,.1);
$white-20: rgba(255,255,255,.2);
$white-30: rgba(255,255,255,.3);
$white-40: rgba(255,255,255,.4);
$white-50: rgba(255,255,255,.5);
$white-60: rgba(255,255,255,.6);
$white-70: rgba(255,255,255,.7);
$white-80: rgba(255,255,255,.8);
$white-90: rgba(255,255,255,.9);

// Color Palette
//-----------------------------
$white: #ffffff;

.white {
  color: $white;
}
$ivory: #F9F9F9;

.ivory {
  color: $ivory;
}
$black: #192F37;

.black {
  color: $black;
}
$red: #913518;

.red {
  color: $red;
}
$grey: #869aa1;

.grey {
  color: $grey;
}
$lt-grey: #d2d2d2;

.lt-grey {
  color: $lt-grey;
}
$mint: #8fb7b3;

.mint {
  color: $mint;
}
$green: #2a4546;

.green {
  color: $green;
}
$blue: #192f37;

.blue {
  color: $blue;
}
$primary-color: $red;

.primary-color {
  color: $red;
}
$secondary-color: $black;

.secondary-color {
  color: $black;
}
