$icomoon-font-path: "../assets/fonts/icons" !default;

@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?ohim50');
  src:  url('#{$icomoon-font-path}/icomoon.eot?ohim50#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.ttf?ohim50') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?ohim50') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?ohim50#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icomoon($icon, $position: "before", $replace: false) {
	// If we're replacing the text, set font-size to 0
	@if $replace {
		font-size: 0;
	}
	// Pseudo-element properties
	&:#{$position} {
		@extend .icon-#{$icon};
		font-family: 'icomoon';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		@if $replace {
			font-size: 1rem;
		}
		@content;
	}
}

.icon-close {
  content: "\e90e";
}
.icon-link {
  content: "\e90d";
}
.icon-chain {
  content: "\e90d";
}
.icon-url {
  content: "\e90d";
}
.icon-uri {
  content: "\e90d";
}
.icon-anchor {
  content: "\e90d";
}
.icon-ticket {
  content: "\e90c";
}
.icon-character-articles {
  content: "\e90f";
}
.icon-character-cocktails {
  content: "\e910";
}
.icon-character-reviews {
  content: "\e911";
}
.icon-character-bars {
  content: "\e912";
}
.icon-character-baiology {
  content: "\e913";
}
.icon-character-events {
  content: "\e914";
}
.icon-email {
  content: "\e90b";
}
.icon-clock {
  content: "\e909";
}
.icon-location {
  content: "\e90a";
}
.icon-arrow-down {
  content: "\e908";
}
.icon-character-1 {
  content: "\e900";
}
.icon-arrow-left {
  content: "\e901";
}
.icon-arrow-right {
  content: "\e902";
}
.icon-facebook {
  content: "\e903";
}
.icon-instagram {
  content: "\e904";
}
.icon-search {
  content: "\e905";
}
.icon-star {
  content: "\e906";
}
.icon-twitter {
  content: "\e907";
}

.facebook {
  @include icomoon("facebook", "after", false);
}

.twitter {
  @include icomoon("twitter", "after", false);
}

.instagram {
  @include icomoon("instagram", "after", false);
}

.email {
  @include icomoon("email", "after", false);
}

.link {
  @include icomoon("link", "after", false);
}
